import Vue from "vue";
import Vuex from "vuex";

import user from "./modules/user";
import routerInfo from "./modules/routerInfo";
import currentMeeting from "./modules/currentMeeting";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: { user, routerInfo, currentMeeting }
});
