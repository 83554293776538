const routerInfo = {
  state: {
    navBarTitle: sessionStorage.getItem("navBarTitle"),
  },
  mutations: {
    SetNavBarTitle: (state, title) => {
      sessionStorage.setItem("navBarTitle", title);
      state.navBarTitle = title;
    },
  },
  actions: {},
  getters: {},
};

export default routerInfo;
