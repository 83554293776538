import router from "./router";
// import store from "./store";

const whiteList = ["/login", "/redirect", "/locallogin"];

router.beforeEach((to, from, next) => {
  let sessionSid = window.sessionStorage.getItem("sid");
  if (sessionSid) {
    if (to.path === "/") {
      next();
    } else if (to.path === "/login") {
      next();
    } else if (to.path === "/redirect") {
      next();
    } else {
      // if (store.state.user.routers.length === 0) {
      //   store.dispatch("GenerateRoutes").then(accessedRoutes => {
      //     router.addRoutes(accessedRoutes);
      //     next({ ...to, replace: true });
      //   });
      // } else {
      //   next();
      // }
      next();
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next();
    } else {
      next(`/login`);
    }
  }
});
