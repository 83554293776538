import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "layout",
    component: () => import("@/views/layout/index.vue"),
    children: [
      {
        path: "index",
        name: "index",
        component: () => import("@/views/index/index.vue"),
        meta: {
          name: "首页"
        }
      },
      {
        path: "meetingList",
        name: "meetingList",
        component: () => import("@/views/meetingList/index.vue"),
        meta: {
          name: "会议工单"
        }
      },
      {
        path: "meetingDetails",
        name: "meetingDetails",
        component: () => import("@/views/meetingList/meetingDetails.vue"),
        meta: {
          name: "工单信息"
        }
      },
      {
        path: "/roleSelect",
        name: "roleSelect",
        component: () => import("@/views/login/rolesSelect.vue"),
        meta: {
          name: "角色选择"
        }
      },
      {
        path: "/pageSelect",
        name: "pageSelect",
        component: () => import("@/views/login/pageSelect.vue"),
        meta: {
          name: "界面选择"
        }
      },
      {
        path: "/addMeeting",
        name: "addMeeting",
        component: () => import("@/views/meetingList/addMeeting.vue"),
        meta: {
          name: store.state.currentMeeting.isEditing ? "编辑工单" : "新建工单"
        }
      },
      {
        path: "/changeMeetingRoom",
        name: "changeMeetingRoom",
        component: () => import("@/views/meetingList/changeMeetingRoom.vue"),
        meta: {
          name: "会议转场"
        }
      },
      {
        path: "/meetingPrepare",
        name: "meetingPrepare",
        component: () => import("@/views/meetingList/meetingPrepare.vue"),
        meta: {
          name: "会前工作"
        }
      },
      {
        path: "/inprogressSchedule",
        name: "inprogressSchedule",
        component: () => import("@/views/meetingList/inprogressSchedule.vue"),
        meta: {
          name: "会中工作"
        }
      },
      {
        path: "/addMeetingInPrecessProblem",
        name: "addMeetingInPrecessProblem",
        component: () =>
          import("@/views/meetingList/addMeetingInPrecessProblem.vue"),
        meta: {
          name: "运维问题"
        }
      },
      {
        path: "/afterMeetingProblem",
        name: "afterMeetingProblem",
        component: () => import("@/views/meetingList/afterMeetingProblem.vue"),
        meta: {
          name: "会后工作"
        }
      },
      {
        path: "/meetingSupport",
        name: "meetingSupport",
        component: () => import("@/views/meetingList/meetingSupport.vue"),
        meta: {
          name: "会议支持"
        }
      },
      {
        path: "/messageList",
        name: "messageList",
        component: () => import("@/views/messageList/index.vue"),
        meta: {
          name: "实时消息"
        }
      },
      {
        path: "/messageDetails",
        name: "messageDetails",
        component: () => import("@/views/messageList/messageDetails.vue"),
        meta: {
          name: "消息详情"
        }
      }
    ]
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/login/login.vue")
  },
  {
    path: "/redirect",
    name: "redirect",
    component: () => import("@/views/login/redirect.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
