import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Vant, { Notify } from "vant";
import VueBetterScroll from "vue2-better-scroll/dist/vue-better-scroll";
import "vant/lib/index.css";
import "./sytle/index.css";
// 引入rem计算方法
import refreshRem from "./utils/fontsizeRefresh";
import "./permission";
var moment = require("moment");
Vue.prototype.$moment = moment;

Vue.prototype.$notify = Notify;
refreshRem(window);
Vue.use(Vant);
Vue.use(VueBetterScroll);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
