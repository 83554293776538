const user = {
  state: {
    token: sessionStorage.getItem("token"),
    name: sessionStorage.getItem("username"),
    roles: sessionStorage.getItem("roles"),
    roleId: window.sessionStorage.getItem("roleId"),
    userRoles: window.sessionStorage.getItem("userRoles"),
    pageList: window.sessionStorage.getItem("pageList"),
    authority: window.sessionStorage.getItem("authority"),
    IsAdmin: window.sessionStorage.getItem("IsAdmin")
  },
  mutations: {
    SetIsAdmin: (state, IsAdmin) => {
      sessionStorage.setItem("IsAdmin", IsAdmin);
      state.IsAdmin = IsAdmin;
    },
    SetRoleId: (state, roleId) => {
      sessionStorage.setItem("roleId", roleId);
      state.roleId = roleId;
    },
    SetUserRole: (state, userRoles) => {
      sessionStorage.setItem("userRoles", userRoles || []);
      state.userRoles = userRoles;
    },
    SetToken: (state, token) => {
      state.token = token;
    },
    SetName: (state, name) => {
      sessionStorage.setItem("username", name);
      state.name = name;
    },
    SetRoles: (state, roles) => {
      sessionStorage.setItem("roles", roles);
      state.roles = roles;
    },
    SetPageList: (state, pageList) => {
      sessionStorage.setItem("pageList", pageList);
      state.pageList = pageList;
    },
    SetAuthority: (state, authority) => {
      sessionStorage.setItem("authority", authority);
      state.authority = authority;
    }
  },
  actions: {
    LogOut({ commit }) {
      return new Promise(resolve => {
        commit("SetToken", "");
        commit("SetName", "");
        commit("SetUserRole", "");
        commit("SetRoleId", "");
        resolve();
      });
    }
  },
  getters: {}
};

export default user;
