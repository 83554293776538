const currentMeeting = {
  state: {
    meeting: null,
    isEditing: false,
    scheduleId: 0,
    message: null,
    isEditingProblem: false,
    currentProblem: null
  },
  mutations: {
    SetCurrentMeeting: (state, meeting) => {
      state.meeting = meeting;
    },
    SetIsEditing: (state, isEditing) => {
      state.isEditing = isEditing;
    },
    SetIsEditingProblem: (state, isEditingProblem) => {
      state.isEditingProblem = isEditingProblem;
    },
    SetCurrentProblem: (state, currentProblem) => {
      state.currentProblem = currentProblem;
    },
    SetCurrentScheduleId: (state, scheduleId) => {
      state.scheduleId = scheduleId;
    },
    SetCurrentMessage: (state, message) => {
      state.message = message;
    }
  },
  actions: {},
  getters: {}
};

export default currentMeeting;
